import React from 'react';
import './App.css';
import Header from './components/fixed/Header'
import Footer from './components/fixed/Footer'
import Pricing from './components/pages/Pricing'
import Payment from './components/pages/Payment'
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core'
import SootSafeTheme from './style/SootSafeTheme'
import { Router } from '@reach/router'
import Offer from './components/offer/Offer';
import PostPurchase from './components/offer/PostPurchase'
import MainOld from './components/pages/MainOld'
import ReactGA from 'react-ga'

const useStyles = makeStyles(theme => ({
  root: {},
  mainContent: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'unset',
    },
    justifyContent: 'center',
    paddingTop: '0rem',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
}))

const googleAnalyticsId = process.env.REACT_APP_GA_ID

if (googleAnalyticsId) {
  ReactGA.initialize(googleAnalyticsId, { 
    debug: true,
    titleCase: false, 
    testMode: false,
  });
}


const Template = ({ children }) => {
  const classes = useStyles()

  if (googleAnalyticsId) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
  return (
    
      <Box style={{overflow: 'hidden'}}>
        <Header />
        <Box id="content" className={classes.mainContent}>
          {children}
        </Box>
        {/* <Footer /> */}
      </Box>
  )
}

function App() {
  return (
    <ThemeProvider theme={SootSafeTheme}>
    <Router>
      <Template path="/">
        <MainOld path="/" />
        <Pricing path="/pricing" />
        <Offer path="/offer/view/:offerId" />
        <Payment path="/offer/accept/:offerId" />
        <PostPurchase path="/offer/thanks" />
      </Template>
    </Router>
    </ThemeProvider>
  );
}

export default App;
