import React, { useState, useEffect } from 'react'

import { StripeProvider, Elements } from 'react-stripe-elements'
import InjectedCheckoutForm from '../stripe/InjectedCheckoutForm'

const Payment = props => {

    const [offer, setOffer] = useState({})
    const [offerNotFound, setOfferNotFound] = useState(false)
    const [offerFetched, setOfferFetched] = useState(false)
    const [clientSecret, setClientSecret] = useState({})
    const [paymentMethod, setPaymentMethod] = useState({})

    const baseApiUrl = process.env.REACT_APP_BASE_API

    const fetchOfferFromServer = async (offerId) => {
        const fetchResult = await fetch(`${baseApiUrl}/api/offer/accept/${offerId}`)
        const result = await fetchResult.json()
        setOffer(result.offer)
        setClientSecret(result.clientSecret)
        setPaymentMethod(result.paymentMethod)
        setOfferFetched(true)
    }

    useEffect(() => {
        const offerId = props.offerId
        if (!offerId) {
            setOfferNotFound(true)
        } else {
            fetchOfferFromServer(offerId)
        }
    }, [])

    if (!offerFetched) return <div>Fetching offer...</div>

    if (offerNotFound) return <div>Could not find that offer. Please <a href="mailto:kristofer@sootsafe.com">contact us</a></div>

    return (
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
            <Elements>
                <InjectedCheckoutForm offer={offer} clientSecret={clientSecret} paymentMethod={paymentMethod} />
            </Elements>
        </StripeProvider>
    )
}

export default Payment