import React, { useState, useEffect } from 'react'
import { Box, Button, Card, makeStyles, Typography, CardHeader, CardContent } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  hero: {
    color: 'white',
    boxSizing: 'border-box',
    width: '100vw',
    background: theme.palette.secondary.dark,
    padding: theme.spacing(2)
  },
  offerHeading: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
  offerCardSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
    width: '80%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100vw',
      margin: 0,
      marginBottom: theme.spacing(1),
    },
  },
  offerCard: {
    flex: 1,
    textAlign: 'left',
    lineHeight: '2rem',
    margin: theme.spacing(3),
    minWidth: '20rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100vw',
      margin: 0,
    },
  },
  offerButton: {
    fontWeight: 'bold',
    width: '80%',
    marginBottom: theme.spacing(2),
  },
}))

const Offer = props => {

  const classes = useStyles()

  const [offer, setOffer] = useState({})
  const [offerNotFound, setOfferNotFound] = useState(false)
  const [offerFetched, setOfferFetched] = useState(false)

  const baseApiUrl = process.env.REACT_APP_BASE_API

  const fetchOfferFromServer = async (offerId) => {
    const fetchResult = await fetch(`${baseApiUrl}/api/offer/get/${offerId}`)
    const offer = await fetchResult.json()
    setOffer(offer)
    setOfferFetched(true)
  }

  useEffect(() => {
    const offerId = props.offerId
    if (!offerId) {
      setOfferNotFound(true)
    } else {
      fetchOfferFromServer(offerId)
    }
  }, [])

  if (offerNotFound) return <Box className={classes.root}>No offer id provided</Box>

  if (!offerFetched) return <Box className={classes.root}>We are fetching your offer. Please wait...</Box>

  if (offer.expired) return <Box className={classes.root}>Ditt erbjudande har gått ut. Kontakta SootSafe, vi fixar det!</Box>

  return (
    <Box className={classes.root}>
      <Box className={classes.hero}>
        <Typography variant="h1" className={classes.offerHeading}>
          {offer.productName}
        </Typography>
        <Typography variant="h4" className={classes.offerSubHeading}>
          {offer.currencySymbol} {offer.price}
        </Typography>    
      </Box>

      <Box className={classes.offerCardSection}>
        <Card className={classes.offerCard}>
          <CardHeader
            title="Detta ingår"
          />
          <CardContent style={{fontWeight: 'bold'}}>
            <ul>
              {
                offer.featureBullets.map((bullet, index) => <li key={index}>{bullet}</li>)
              }
            </ul>
          </CardContent>
        </Card>

        <Card className={classes.offerCard}>
        <CardHeader
            title="Beskrivning"
          />
          <CardContent>
            { offer.productDescription.map((description, index) => <Box key={index}>{description}</Box>) }
          </CardContent>
        </Card>
      </Box>

      <Button variant="contained" size="large" color="secondary" className={classes.offerButton} href={`/offer/accept/${props.offerId}`}>
        Gå vidare
      </Button>
   
    </Box>
  )
}

export default Offer