import React, { useState } from 'react';
import { Box, Card, CardHeader, CardContent, createStyles, CardActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { EmailModal, ConfirmModal } from '../signup/EmailModal'

const useStyles = makeStyles(theme => createStyles({
    root: {
        paddingTop: '4rem',
        width: '80vw',
        // maxWidth: '80%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    tierCards: {
        display: "flex",
        justifyContent: "center",
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100vw',
            margin: 0,
            flex: 1,
            marginBottom: theme.spacing(1),
            flexDirection: 'column',
          },
    },
    pricingCard: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.root.spacing(.8),
        flexGrow: 1,
        flex: 1,
        maxWidth: '20rem',
        minWidth: '12rem',
        border: '1px solid #ddd',
        [theme.breakpoints.down('sm')]: {
            margin: theme.root.spacing(0),
            flexGrow: 1,
            flex: 1,
            minWidth: '100vw',
            marginBottom: theme.spacing(2),
        },
    },
    header: {
        borderBottom: '1px solid #ddd',
    },
    mainPrice: {
        fontSize: '3.5rem',
        fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
    },
    tierFeatures: {
        listStyleType: 'disc',
        marginBottom: 0,
        paddingLeft: 0,
        boxSizing: 'border-box',
        display: 'block',
        marginBlockStart: '1rem',
        marginBlockEnd: '1rem',
        marginInlineStart: 0,
        marginInlineEnd: 0,
    },
    tierFeature: {
        display: 'block',
        position: 'relative',
        border: '1px solid #ddd',
        padding: theme.root.padding(.8),
        marginBottom: '-1px',
        marginLeft: '-1px',
        marginRight: '-1px',
        boxSizing: 'border-box',
        textAlign: '-webkit-match-parent',
        borderRadius: 0,
    },
    pricingContent: {
        padding: 0,
        marginTop: theme.root.spacing(.8),
    },
    pricingAction: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    signUpButton: {
        fontSize: 'large',
    },
    superEnterpriseCard: {
        marginTop: theme.root.spacing(3),
        margin: theme.root.spacing(.8),
        border: '1px solid #ddd',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100vw',
            margin: 0,
            marginBottom: theme.spacing(2),
        },
    },
    swell: {
        [theme.breakpoints.up('md')]: {
            transform: 'scale(1.2)',
            zIndex: 10,
        },
    },
}))

const Pricing = (props) => {

    const classes = useStyles()

    const baseApiUrl = process.env.REACT_APP_BASE_API

    const [modalOpen, setModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [emailAddress, setEmailAddress] = useState('')
    
    const createNewSubscriber = emailAddress => {
        fetch(`${baseApiUrl}/api/subscriber/new/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: emailAddress,
                campaignId: 906991797,
            })
        })
        setModalOpen(false)
        setConfirmModalOpen(true)
        timeoutConfirm()
    }

    const timeoutConfirm = () => {
        setTimeout(() => {setConfirmModalOpen(false)}, 3000)
    }

    const header = "En strålande idé!"
    const body = [
        "SootSafe planerar att släppas under första halvåret 2020. Ange din mailadress nedan så skickar vi information till dig när SootSafe är tillgängligt.",
        "Vi kommer inte att dela med oss av din adress till tredje part, och du kan avregistrera dig när du vill."
    ]


    const createCard = (price, tierName, users, activeProjects, archivedProjects, style = '') => (
        <Card className={[classes.pricingCard, style].join(' ')}>
            <CardHeader title={tierName} className={classes.header} />
            <CardContent className={classes.pricingContent}>
                <div className={classes.mainPrice}>€ {price}</div>
                <div className={classes.pricePeriod}>/månad</div>

                <ul className={classes.tierFeatures}>
                    <li className={classes.tierFeature}>
                        <strong>{users}</strong> Användare
                    </li>
                    <li className={classes.tierFeature}>
                        <strong>{activeProjects}</strong> Aktiva projekt
                    </li>
                    <li className={classes.tierFeature}>
                        <strong>{archivedProjects}</strong> Arkiverade projekt
                    </li>
                    <li className={classes.tierFeature}>
                        <strong>Obegränsade</strong> rapporter
                    </li>
                    <li className={classes.tierFeature}>
                        Teknisk support <strong>ingår</strong>
                    </li>
                </ul>
            </CardContent>
            <CardActions className={classes.pricingAction} >
                <Button 
                    color="secondary" 
                    variant="contained" 
                    className={classes.signUpButton}
                    onClick={() => setModalOpen(true)}
                    >
                        Sign Up!
                    </Button>
            </CardActions>
        </Card>
    )

    return (
        <Box className={classes.root}>
            <Box className={classes.tierCards}>
                { createCard(49, "Solo", 1, 3, 20) }
                { createCard(89, "Agile", 4, 15, 50, classes.swell) }
                { createCard(149, "Enterprise", 10, 40, 100) }
            </Box>

            <Card className={classes.superEnterpriseCard}>
                <CardHeader title="Behöver du mer?"  />
                <CardContent className={classes.pricingContent}>
                    Kontakta oss för mer information och pris
                </CardContent>
            </Card>

            <EmailModal 
                open={modalOpen}
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                createNewSubscriber={createNewSubscriber}
                setModalOpen={setModalOpen}
                header={header}
                body={body}
            />
            <ConfirmModal open={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />
        </Box>
    )
}

export default Pricing