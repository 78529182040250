import React, { useState } from 'react'
import { Button, makeStyles, Box, Typography, Fab } from '@material-ui/core'
import { EmailModal, ConfirmModal } from '../signup/EmailModal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faObjectUngroup, faClock, faNewspaper, faUsers, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import SuperHeroBackground from '../../resources/images/SuperHeroBackground.jpg'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100vw',
    },
    superHero: {
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        boxSizing: 'border-box',
        maxWidth: '100vw',
        width: '100vw',
        minHeight: '100vh',
        backgroundImage: `url(${SuperHeroBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    superHeroTitle: {
        fontFamily: "'Ubuntu', sans-serif",
        textAlign: 'center',
    },
    superHeroSubTitle: {
        color: '#DDD',
        margin: theme.spacing(2),
    },
    superHeroButton: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        background: 'white',
        color: 'black',
        fontWeight: 'bold',

    },
    superHeroContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    superHeroContentInner: {
        maxWidth: '1000px',
    },
    heroContentInner: {
        maxWidth: '50rem',
        flex: 1,
        padding: theme.spacing(2),
    },
    hero: {
        display: 'flex',
        color: 'white',
        minHeight: '40vh',
        width: '100vw',
        background: theme.palette.secondary.main,
        // padding: theme.spacing(2),
    },
    whiteHero: {
        color: '#333',
        width: '100vw',
        background: 'white',
        padding: theme.spacing(2)
    },
    blackHero: {
        color: 'white',
        width: '100vw',
        background: theme.palette.primary.main,
        padding: theme.spacing(2)
    },
    container: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    projectContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1,
        justifyContent: 'center',

    },
    projectIcon: {
        fontSize: '4em',
        color: theme.palette.primary.dark,
    },
    project: {
        maxWidth: '15rem',
        margin: theme.root.spacing(3),
    },
    heroRuler: {
        maxWidth: '50px',
        borderColor: theme.palette.secondary.main,
        borderWidth: '3px',
        borderStyle: 'solid',
    },
    ruler: {
        borderStyle: 'solid',
    },
}))

const baseApiUrl = process.env.REACT_APP_BASE_API

const MainOld = props => {
    
    const classes = useStyles()
    
    const [modalOpen, setModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [emailAddress, setEmailAddress] = useState('')
    
    const createNewSubscriber = emailAddress => {
        fetch(`${baseApiUrl}/api/subscriber/new/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: emailAddress,
                campaignId: 906991797,
            })
        })
        setModalOpen(false)
        setConfirmModalOpen(true)
        timeoutConfirm()
    }

    const timeoutConfirm = () => {
        setTimeout(() => {setConfirmModalOpen(false)}, 3000)
    }

    const header = "Ange din mailadress"
    const body = ["Vi kommer inte att dela med oss av din adress till tredje part, och du kan avregistrera dig när du vill."]


    return (
        <div className={classes.root}>
            <Box className={classes.superHero}>
                <div className={classes.superHeroContent}>
                    <div className={classes.superHeroContentInner}>
                        <Typography variant="h1" className={classes.superHeroTitle}>Enklare beräkning, snabbare resultat</Typography>
                        <hr className={classes.heroRuler} />
                        <Typography variant="h5" className={classes.superHeroSubTitle}>Förbättrad dimensionering av ventilationstekniskt brandskydd</Typography>
                        <Fab variant="extended" aria-label="more info" className={classes.superHeroButton} onClick={() => setModalOpen(true)}>Ge mig mer info!</Fab>
                    </div>
                </div>
            </Box>


            <Box className={classes.hero} id="about">
                <div className={classes.container}>
                    <div className={classes.superHeroContent}>
                        <div className={classes.heroContentInner}>
                            <Typography variant="h4" className={classes.superHeroTitle}>Dimensionering behöver inte vara krångligt</Typography>
                            <hr className={classes.ruler} />
                            <p className="text-faded">Varför måste dimensioneringskontroll ta dagar, eller ens timmar? Med SootSafe kan du bygga ditt system i en enkel drag-and-drop-miljö, direkt i din webbläsare.</p>
                            <Fab variant="extended" aria-label="more info" className={classes.superHeroButton} onClick={() => setModalOpen(true)}>Ge mig mer info!</Fab>
                        </div>
                    </div>
                </div>
            </Box>

            <Box id="services" className={classes.whiteHero}>

                <div className={classes.container}>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className={classes.superHeroTitle}>Vad får jag med SootSafe</h2>
                            <hr className="primary" />
                        </div>
                    </div>
                </div>

                <div className={classes.container}>
                    <div className={classes.projectContainer}>
                        <div className={classes.project}>
                            <FontAwesomeIcon className={classes.projectIcon} icon={faObjectUngroup} />
                            <i className="fa fa-4x fa-object-ungroup wow bounceIn text-primary"></i>
                            <h3>Projekthantering</h3>

                            <p className="text-muted">Hitta enkelt bland dina projekt och ritningar.</p>
                        </div>

                        <div className={classes.project}>
                            <FontAwesomeIcon className={classes.projectIcon} icon={faClock} />
                            <i className="fa fa-4x fa-clock-o wow bounceIn text-primary"></i>
                            <h3>Snabbt till leverans</h3>
                            <p className="text-muted">Med SootSafe får du ett enkelt och lättanvänt gränssnitt att jobba med.</p>
                        </div>

                        <div className={classes.project}>
                            <FontAwesomeIcon className={classes.projectIcon} icon={faNewspaper} />
                            <i className="fa fa-4x fa-newspaper-o wow bounceIn text-primary"></i>
                            <h3>Up to Date</h3>
                            <p className="text-muted">Vi ser till att beräkningsmodeller hålls uppdaterade enligt BBR och BBRAD.</p>
                        </div>

                        <div className={classes.project}>
                            <FontAwesomeIcon className={classes.projectIcon} icon={faUsers} />
                            <i className="fa fa-4x fa-users wow bounceIn text-primary"></i>
                            <h3>Dela med dig</h3>
                            <p className="text-muted">Samarbeta med dina kollegor direkt i SootSafe</p>
                        </div>

                    </div>
                </div>
            </Box>

            <Box className={classes.blackHero}>
                <div className="container text-center">
                    <div className="call-to-action">
                        <h2 className={classes.superHeroTitle}>Ja, jag är redo att spara tid!</h2>
                        <Fab variant="extended" aria-label="more info" className={classes.superHeroButton} onClick={() => setModalOpen(true)}>Ge mig mer info!</Fab>
                    </div>
                </div>
            </Box>

            <section id="contact">
                <div className={classes.container}>
                    <div className="row">
                        <div className="col-lg-8 col-lg-offset-2 text-center">
                            <h2 className={classes.superHeroTitle}>Låt oss talas vid!</h2>
                            <hr className="primary" />
                            <p>Vill du veta mer om SootSafe? Hör av dig, så får du svar på dina frågor</p>
                        </div>
                    </div>
                </div>

                <div className={classes.container}>
                    <div className={classes.projectContainer}>
                        <div className={classes.project}>
                            <FontAwesomeIcon className={classes.projectIcon} icon={faPhone} />
                            <p><Button href="tel:+46709221997">&#43;46 709 22 19 97</Button></p>
                        </div>
                        <div className={classes.project}>
                            <FontAwesomeIcon className={classes.projectIcon} icon={faEnvelope} />
                            <p><Button href="mailto:info@sootsafe.com">info@sootsafe.com</Button></p>
                        </div>
                    </div>
                </div>
            </section>

            <EmailModal 
                open={modalOpen}
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                createNewSubscriber={createNewSubscriber}
                setModalOpen={setModalOpen}
                header={header}
                body={body}
            />
            <ConfirmModal open={confirmModalOpen} setConfirmModalOpen={setConfirmModalOpen} />
        </div>

    )
}

export default MainOld