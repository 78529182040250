import React, { useState } from 'react';
import { injectStripe } from 'react-stripe-elements';

import CardSection from './CardSection';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Button, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Box } from '@material-ui/core';
import AddressForm from '../signup/AddressForm';
import { makeStyles } from '@material-ui/styles';
import { navigate } from '@reach/router'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
      width: '100vw',
      margin: 0,
    }
  },
  nextButton: {
    marginTop: theme.spacing(2),
  },
  errorMessageBox: {
    border: '1px solid red',
    borderRadius: '3px',
    margin: theme.spacing(2),
    padding: theme.spacing(1)
  },
}))


const CheckoutForm = props => {

  const createNewCustomer = async (customerData) => {
    const baseApiUrl = process.env.REACT_APP_BASE_API

    const fetchResult = await fetch(`${baseApiUrl}/api/customer/create`, {
      method: 'POST',
      body: JSON.stringify(customerData),
    })

    return await fetchResult.json()
  }

  const handleSubmit = async (ev) => {
    setErrorMessage('')
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    const { customer } = props.offer

    const customerData = {
      receipt_email: customer.email,
      payment_method: props.paymentMethod,
      // save_payment_method: true,
      shipping: {
        name: customer.name,
        phone: customer.telephone,
        address: {
          line1: customer.streetAddress,
          city: customer.city,
          postal_code: customer.postalCode,
          country: customer.country,
        }
      }
    }

    // const createdCustomer = await createNewCustomer(customerData)

    // console.log('JSON', createdCustomer)

    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment
    const paymentResult = await props.stripe.handleCardPayment(props.clientSecret, customerData)

    if (paymentResult.error) {
      setErrorMessage(paymentResult.error.message)
    } else {
      console.log('Display thank-you-page. It all went well (so far)', paymentResult)
      navigate('/offer/thanks')
    }
  };

  const [expansionIndex, setExpansionIndex] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [customerAddress, setCustomerAddress] = useState({...props.offer.customer})

  const changeExpansion = index => (event, isExpanded = true) => {
    setErrorMessage('')
    setExpansionIndex(isExpanded ? index % 3 : false)
  }

  const updateAddressDetails = receivedAddress => {
    setCustomerAddress(a => ({receivedAddress, ...a}))
  }

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <ExpansionPanel expanded={expansionIndex === 0} onChange={changeExpansion(0)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading}>Name and address</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box display="flex" flexDirection="column" flexGrow="1">
            <AddressForm customer={customerAddress} onUpdate={updateAddressDetails} />

            <Button
              onClick={changeExpansion(1)}
              color="secondary"
              variant="contained"
              className={classes.nextButton}
            >
              Next
            </Button>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>


      <ExpansionPanel expanded={expansionIndex === 1} onChange={changeExpansion(1)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading}>Credit card details</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box display="flex" flexDirection="column" flexGrow="1">
            <CardSection />

            <Button
              onClick={changeExpansion(2)}
              color="secondary"
              variant="contained"
              className={classes.nextButton}
            >
              Next
            </Button>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expansionIndex === 2} onChange={changeExpansion(2)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading}>Confirm</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box display="flex" flexDirection="column" flexGrow="1">
            A confirmation email will be sent to <i>{customerAddress.email}</i>
            <Button
              onClick={handleSubmit}
              color="secondary"
              variant="contained"
              className={classes.nextButton}
            >
              Confirm Order
            </Button>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      {
        errorMessage ?
          <Box id="error-message" className={classes.errorMessageBox}>
            <Typography variant="h6">Oh, no! Something went wrong...</Typography>
            {errorMessage}
          </Box>
          :
          <></>
      }
    </Box>
  );
}

export default injectStripe(CheckoutForm);