import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@material-ui/core';
import { Link } from '@reach/router'

const useStyles = theme => ({
    menuRoot: {
        // background: 'black',
    },
    linkBox: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 50,
        textDecoration: 'none',
        "&:hover": {
            background: theme.palette.primary.dark,
            color: theme.palette.primary.main,
        },
    },
    menuLink: {
        marginRight: theme.root.spacing(1.5),
        marginLeft: theme.root.spacing(1.5),
        color: 'black',
    },
})

class SimpleMenu extends React.Component {

    constructor(props) {
        super(props)
    }

    handleClose = () => {
        this.props.onClose()
    };

    render() {
        const { classes } = this.props
        return (
            <Menu
                id="simple-menu"
                anchorEl={this.props.anchorEl}
                keepMounted
                open={Boolean(this.props.anchorEl)}
                onClose={this.props.handleClose}
                className={classes.menuRoot}
            >
                <Link to="/" className={classes.linkBox}>
                    <MenuItem onClick={this.handleClose} className={classes.menuLink}>Home</MenuItem>
                </Link>

                <Link to="/pricing" className={classes.linkBox}>
                    <MenuItem onClick={this.handleClose} className={classes.menuLink}>Pricing</MenuItem>
                </Link>

                {/* <Link to="/news" className={classes.linkBox}>
                    <MenuItem onClick={this.handleClose} className={classes.menuLink}>News</MenuItem>
                </Link> */}
            </Menu>
        );

    }
}

export default withStyles(useStyles)(SimpleMenu)