import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button, Box, ClickAwayListener } from '@material-ui/core';
import { Link } from '@reach/router'
import logo from '../../resources/images/logo.svg'
import SimpleMenu from './MobileMenu';
import { Menu as MenuIcon } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  headerLogoLink: {
    height: 50,
    width: 440,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  headerLogo: {
    height: 50,
    width: 440,
    backgroundImage: 'linear-gradient(to right, white, black)',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  loginButton: {
    marginRight: theme.root.spacing(1),
  },
  menuLinkSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
    marginRight: theme.root.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  linkBox: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 50,
    textDecoration: 'none',
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  menuLink: {
    marginRight: theme.root.spacing(1.5),
    marginLeft: theme.root.spacing(1.5),
    color: 'white',
  },
  mobileMenuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function Header() {
  const classes = useStyles();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const openMobileMenu = event => {
    setMenuAnchorEl(event.currentTarget)
  }

  const closeMobileMenu = () => {
    setMenuAnchorEl(null)
  }

  return (
    <AppBar position="static" display="flex">
      <Toolbar disableGutters className={classes.root}>
        <a href="/" className={classes.headerLogoLink}>
          <img className={classes.headerLogo} src={logo} />
        </a>

        <Box className={classes.menuLinkSection}>
          <Link to="/" className={classes.linkBox}>
            <Typography variant="h6" className={classes.menuLink}> Home </Typography>
          </Link>
          <Link to="/pricing" className={classes.linkBox}>
            <Typography variant="h6" className={classes.menuLink}> Pricing </Typography>
          </Link>
          {/* <Link to="/news" className={classes.linkBox}>
            <Typography variant="h6" className={classes.menuLink}> News </Typography>
          </Link> */}
        </Box>

        {/* <Button variant="contained" color="secondary" className={classes.loginButton}>Login</Button> */}

        <ClickAwayListener onClickAway={closeMobileMenu}>
          <Box className={classes.mobileMenuButton}>
            <Button
              aria-controls="simple-menu"
              variant="contained"
              color="primary"
              aria-haspopup="true"
              className={classes.loginButton}
              onClick={openMobileMenu}
            >
              <MenuIcon />
            </Button>

            <SimpleMenu anchorEl={menuAnchorEl} onClose={closeMobileMenu} />
          </Box>
        </ClickAwayListener>
      </Toolbar>
    </AppBar>
  );
}
