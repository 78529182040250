import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../resources/images/logo.svg'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        background: theme.palette.primary.main,
        color: theme.palette.primary.light,
    },
    section: {
        flex: 1,
        borderRight: '1px solid #ddd',
    }
}));

export default function Footer() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <img src={logo} />
            </div>
            <div className={classes.section}>
                Testing
            </div>
        </div>
    );
}
