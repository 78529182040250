import React from 'react'
import { Box, TextField, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import countries from './Countries.json'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '80%',
    },
    select: {
        textAlign: 'left',
    },
}))

const AddressForm = props => {

    const classes = useStyles()

    const { customer } = props

    const updateAddressDetails = fieldName => event => {
        customer[fieldName] = event.target.value
        props.onUpdate(customer)
    }

    return (
        <Box className={classes.root}>
            <TextField
                autoComplete="companyName"
                label="Company Name"
                required
                className={classes.textField}
                value={props.customer.companyName}
                onChange={updateAddressDetails('companyName')}
                margin="normal"
            />

            <TextField
                autoComplete="name"
                name="cc-name"
                label="Full Name (as it appears on card)"
                required
                className={classes.textField}
                value={props.customer.name}
                onChange={updateAddressDetails('name')}
                margin="normal"
            />

            <TextField
                autoComplete="email"
                label="E-mail"
                name="email"
                type="email"
                required
                className={classes.textField}
                value={props.customer.email}
                onChange={updateAddressDetails('email')}
                margin="normal"
            />

            <TextField
                autoComplete="tel"
                label="Telephone Number"
                name="phone"
                type="tel"
                className={classes.textField}
                value={props.customer.telephone}
                onChange={updateAddressDetails('telephone')}
                margin="normal"
            />

            <TextField
                autoComplete="shipping street-address"
                label="Street address"
                name="ship-address"
                className={classes.textField}
                value={props.customer.streetAddress}
                onChange={updateAddressDetails('streetAddress')}
                margin="normal"
            />

            <TextField
                autoComplete="shipping"
                label="City"
                name="ship-city"
                className={classes.textField}
                value={props.customer.city}
                onChange={updateAddressDetails('city')}
                margin="normal"
            />

            <TextField
                autoComplete="shipping postal-code"
                label="Postal Code"
                name="ship-zip"
                className={classes.textField}
                value={props.customer.postalCode}
                onChange={updateAddressDetails('postalCode')}
                margin="normal"
            />

            <Select
                value={props.customer.country}
                onChange={updateAddressDetails('country')}
                label="Country"
                className={classes.select}
                inputProps={{
                    name: 'Country',
                    id: 'country',
                }}
            >
                {
                    countries.map(c => <MenuItem key={c.code} value={c.name}>{c.name}</MenuItem>)
                }
            </Select>

        </Box>
    )
}

export default AddressForm