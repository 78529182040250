import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Modal, Card, TextField, Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    modalCloseButton: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    modalStyle: {
        outline: 'none',
        [theme.breakpoints.down('sm')]: {
            flex: 1,
            height: '100%',
            padding: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50rem',
            border: 'none',
            padding: theme.spacing(3),
        },
    },
    textField: {
        width: '80%',
    },
}))



const EmailModal = props => {
    const classes = useStyles()
    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.open}
            onClose={() => props.setModalOpen(false)}
            selectable="false"
        >
            <Card className={classes.modalStyle}>
                <h2 id="simple-modal-title">{props.header}</h2>
                <div id="simple-modal-description">
                    {props.body.map((row, index) => <div key={index}>{row}</div>)}
                </div>
                <div>
                    <TextField
                        id="emailfield"
                        autoComplete="email"
                        label="E-post"
                        name="email"
                        type="email"
                        className={classes.textField}
                        value={props.emailAddress}
                        onChange={(e) => props.setEmailAddress(e.target.value)}
                        margin="normal"
                    />
                </div>

                <Button variant="contained" color="secondary" onClick={() => props.createNewSubscriber(props.emailAddress)}>Registrera address</Button>
                <Button variant="contained" color="primary" className={classes.modalCloseButton} onClick={() => props.setModalOpen(false)}>Avbryt</Button>

            </Card>
        </Modal>
    )
}

const ConfirmModal = props => {
    const classes = useStyles()
    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.open}
            onClose={() => props.setConfirmModalOpen(false)}
        >
            <Card className={classes.modalStyle}>
                <h2 id="simple-modal-title">Välkommen</h2>
                <p id="simple-modal-description">
                    Din adress är registrerad
                </p>

                <Button variant="contained" color="secondary" onClick={() => props.setConfirmModalOpen(false)}>Stäng</Button>
            </Card>
        </Modal>
    )
}

export {ConfirmModal, EmailModal}