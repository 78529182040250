// CardSection.js
import React from 'react';
import { CardElement } from 'react-stripe-elements';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  StripeElement: {
    minWidth: '35em',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
}))

const CardSection = props => {

  const classes = useStyles()

  return (
    <CardElement hidePostalCode={true} style={{ base: { fontSize: '18px' } }} className={classes.StripeElement} />
  )
}

export default CardSection