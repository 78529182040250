import React from 'react'
import { Typography, Box, Button } from '@material-ui/core'
import { Link } from '@reach/router'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    root: {
        flexDirection: 'column',
        width: '100%',
    },
    hero: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'white',
        boxSizing: 'border-box',
        width: '100vw',
        background: theme.palette.secondary.dark,
        padding: theme.spacing(2)
    },
    breadSection: {
        flexDirection: 'column',
        margin: theme.spacing(1),
        maxWidth: '50%',
    },
    heroLink: {
        color: 'lightgray',
    },
    backButton: {
        margin: theme.spacing(2),
        fontWeight: 'bold',
    }
}))

const PostPurchase = props => {

    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Box className={classes.hero}>
                <Typography variant="h2">Tack så mycket</Typography>
                <Typography variant="h6">...och välkommen till SootSafe!</Typography>

                <Box className={classes.breadSection}>

                    SootSafe är till för att göra ditt jobb och dina dagliga uppgifter lättare.
                    <br />
                    Om du har frågor om hur man andvänder produkten, eller synpunkter på utseende, funktionalitet eller kvalitet,
                    tveka inte att <Link to="mailto:kristofer@sootsafe.com" className={classes.heroLink}>höra av dig</Link>!
                    <br />
                    Du kommer att få mer information via mail.
                    <br />
                    <Button className={classes.backButton} variant="contained" href="/">Ta mig till startsidan</Button>
                    <br />
                </Box>
            </Box>
        </Box>
    )
}

export default PostPurchase